import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import * as styles from './WhyInvozone.module.scss';

const WhyInvozone = ({strapiData}) => {
  {console.log(strapiData,"strapiData?.title")}
  const reasons = [
    {
      number: "01",
      title: "Expertise in AI, 3D Rendering, and WebGL."
    },
    {
      number: "02",
      title: "Proven Ability To Overcome Complex Challenges."
    },
    {
      number: "03",
      title: "Scalable Solutions Tailored For Diverse Industries."
    },
    {
      number: "04",
      title: "Backend System, Avatar End User Communication"
    }
  ];

  return (

    <div className={styles.whySection}>
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className={styles.mainHeading}   dangerouslySetInnerHTML={{ __html: strapiData?.title}} />
          </Col>
          <Col lg={12}>
            <Row>
              {strapiData?.cards.map((reason, index) => (
                <Col lg={6} key={index}>
                  <div className={styles.reasonCard}>
                    <span className={styles.number}>{String(index + 1).padStart(2, '0')}</span>
                    <div className={styles.titleContainer}>
                      <p className={styles.title}>{reason.title}</p>
             
                      <p
                className={styles.titleOther}
                  dangerouslySetInnerHTML={{ __html: reason?.description?.description}}
                />
              
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyInvozone;