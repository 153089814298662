import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Progress.module.scss"

const cardData = [
  {
    heading: "I'm A",
    subHeading: "Startup.",
    description: "Remarkable ideas often stall without the right team to bring them to life",
    textColor: "black",
    button : "Get Started",
    link: "/software-development-services/startup/",
  },
  {
    heading: "I'm An",
    subHeading: "Enterprise.",
    description: "Scaling without the right infrastructure and expertise can lead to costly bottlenecks.",
    textColor: "white",
    button : "Explore More",
    link: "/software-development-services/enterprise/",

  },
  {
    heading: "I Need A",
    subHeading: "Rescue.",
    description: "A messy codebase and tech debt are suffocating your progress.",
    button : "Need a Fix",
    textColor: "black",
    link: "/software-development-services/rescue/",
  },
]

const Progress = () => {
  return (
    <div className={styles.progressSec}>
      <Container>
      
        <div className={styles.concerns}>
          <Row className="justify-content-between align-items-center">
            <Col md={8}>
              <h2>Book Free Demo</h2>
              <br />
              <p>Tired of losing customers? Let's build your churn prediction solution.</p>
            </Col>
            <Col md={4} className="text-end">
              <div className={styles.cardFooter}>
                <Link to={"/contact-us/"} className={`${styles.bookDemo} newBtn`} style={{color: "white"}}>
                  Book a Free Demo
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Progress
