import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import * as styles from './workFlow.module.scss';
import  workFlow from "../../images/churn/arrowBanner.svg"
import  Screen from "../../images/churn/screeen1.svg"
const Workflow = () => {
 

  return (

   <Container>
    <h2 className={styles.mainHeading}>Workflow</h2>
    <div className={styles.workFlowContainer}>
<img  src={"https://invozone-backend.s3.us-east-1.amazonaws.com/churn_portfolio_a540956797.webp"} alt="worrkFlow" />
</div>
<img  src={"https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1991422447_ff30d0958c.webp"} alt="worrkFlow" />
   </Container>
   
  );
};

export default Workflow;