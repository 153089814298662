// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner1-module--arrowAnimation--f40ff";
export var backgroundImg = "Banner1-module--backgroundImg--91819";
export var bannerCurve = "Banner1-module--bannerCurve--a5e66";
export var bannerHeading = "Banner1-module--bannerHeading--94233";
export var bannerText = "Banner1-module--bannerText--7f739";
export var btn = "Banner1-module--btn--02465";
export var btne = "Banner1-module--btne--bd05b";
export var curve = "Banner1-module--curve--2bdf4";
export var dedicated = "Banner1-module--dedicated--d7b6b";
export var dedicatedBanner = "Banner1-module--dedicatedBanner--e2f83";
export var dedicatedHeadingStatic = "Banner1-module--dedicatedHeadingStatic--17909";
export var description = "Banner1-module--description--5c630";
export var descriptionText = "Banner1-module--descriptionText--18e60";
export var headingIntro = "Banner1-module--headingIntro--6bc2f";
export var innerDedicated = "Banner1-module--innerDedicated--fdba9";
export var mainTitle = "Banner1-module--mainTitle--fd491";
export var newBtn = "Banner1-module--newBtn--a2085";
export var paragraphAi = "Banner1-module--paragraphAi--b664e";
export var sliderButtons = "Banner1-module--sliderButtons--f9938";
export var subTitle = "Banner1-module--subTitle--d8cba";