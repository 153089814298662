import React from 'react';
import { Container } from 'react-bootstrap';
import * as styles from './Impact.module.scss';
import  FormImage  from "../../images/churn/FormImage.svg"
const Impact = ({ strapiData }) => {
  return (
    <>
    <div className={styles.impactSection}>
      {/* Green background image with overlay */}
      <div className={styles.backgroundImage}>
        <img 
          src={strapiData?.backgroundImage?.localFile?.publicURL || " https://invozone-backend.s3.us-east-1.amazonaws.com/feature_19_sm_73e5d82142.webp"} 
          alt="Background Pattern" 
          className={styles.bgPattern}
        />
        <div className={styles.overlay}></div>
      </div>

      <Container>
        <div className={styles.content}>
          <h2 className={styles.title}>{strapiData?.title}</h2>
          <p className={styles.description}>{strapiData?.subTitle}</p>

          {/* Local Internet Service Provider Image */}
         
        </div>
     
      </Container>
    </div>
    <Container>
        <div className={styles.serviceCard}>
        <img 
          src={strapiData?.serviceImage?.localFile?.publicURL || FormImage} 
          alt="Local Internet Service Provider" 
          className={styles.serviceImage}
        />
      </div>
      </Container>
      </>
  );
};

export default Impact;
